import ApiService from "@/core/services/ApiService";
import { FormularioEstado } from "@/enums";
import axios, { AxiosError } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { BackendError, ServicioProgramado } from "../models";
import { FamiliaFormulario } from "./FamiliasFormulariosModule";
import { ServicioProfesionalListado } from "./ServiciosListadoModule";

@Module({ namespaced: true, name: "ServiciosProgramadosModule" })
export class ServiciosProgramadosModule extends VuexModule {
  errors: BackendError | null = null;
  servicios: ServicioProgramado[] = [];
  serviciosColectivos: ServicioProgramado[] = [];
  servicio: ServicioProgramado | null = null;
  profesionales: ServicioProfesionalListado[] = [];
  formulario: FamiliaFormulario | null = null;

  @Action
  fetchListado(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/servicios-programados"
        : `/comarcas/${comarcaId}/servicios-programados`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "servicio",
              scope: {
                include: [
                  {
                    relation: "menoresServicio",
                    scope: { where: { estado: "APROBADO" } },
                  },
                ],
              },
            },
            {
              relation: "peticionFormularios",
              scope: { fields: ["id", "estado", "servicioProgramadoId"] },
            },
          ],
        },
      },
    };
    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        this.setData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchById({ comarcaId, id }: { comarcaId: string; id: string }) {
    const url =
      comarcaId == null
        ? `/servicios-programados/${id}`
        : `/comarcas/${comarcaId}/servicios-programados/${id}`;

    return ApiService.get(url, {
      params: {
        filter: {
          include: [
            {
              relation: "servicio",
              scope: {
                include: [
                  {
                    relation: "profesionalesAsignados",
                    scope: { include: [{ relation: "profesional" }] },
                  },
                  {
                    relation: "menores",
                    scope: { include: [{ relation: "familia" }] },
                  },
                  {
                    relation: "respuestasEncuesta",
                  },
                ],
              },
            },
            { relation: "documentos" },
            {
              relation: "peticionFormularios",
              scope: {
                include: [
                  {
                    relation: "familia",
                  },
                  {
                    relation: "peticionFormulario",
                    scope: {
                      where: { tipo: "FAMILIA" },
                      fields: ["id", "datosSolicitud", "estado"],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        this.setDetailData(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServiciosColectivosSolicitados(comarcaId?: string) {
    const url =
      comarcaId == null
        ? "/peticion-formularios/servicios-colectivos"
        : `/comarcas/${comarcaId}/peticion-formularios/servicios-colectivos`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "familia",
              scope: {
                fields: [
                  "id",
                  "dni",
                  "nombre",
                  "apellidos",
                  "email",
                  "telefono",
                  "localidad",
                  "conIngresos",
                  "ingresos",
                  "familiaPrioritaria",
                ],
              },
            },
            {
              relation: "servicioProgramado",
              scope: {
                fields: ["id", "nombre"],
                include: [
                  {
                    relation: "servicio",
                    scope: {
                      fields: [
                        "id",
                        "lugarEspacioColectivo",
                        "lugarPrestacion",
                        "otrosEspacioColectivo",
                        "servicioProgramadoId",
                      ],
                    },
                  },
                ],
              },
            },
            {
              relation: "peticionFormulario",
              scope: {
                fields: ["id", "datosSolicitud"],
              },
            },
          ],
          where: { servicioProgramadoId: { neq: null } },
          order: ["createdOn DESC"],
        },
      },
    };

    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        const dataSorted = data.sort((a, b) => {
          const aNegativo = [
            FormularioEstado.noApto,
            FormularioEstado.rechazado,
            FormularioEstado.realizadorPorError,
          ].includes(a.estado);
          const bNegativo = [
            FormularioEstado.noApto,
            FormularioEstado.rechazado,
            FormularioEstado.realizadorPorError,
          ].includes(b.estado);

          if (aNegativo && bNegativo) {
            return a.createdOn - b.createdOn;
          } else if (aNegativo || bNegativo) {
            return aNegativo ? 1 : -1;
          }

          return a.createdOn - b.createdOn;
        });
        this.setDataColectivos(dataSorted);
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServiciosColectivosSolicitadosDetalle({
    comarcaId,
    id,
  }: {
    comarcaId: string;
    id: string;
  }) {
    const url =
      comarcaId == null
        ? `/peticion-formularios/servicios-colectivos/${id}`
        : `/comarcas/${comarcaId}/peticion-formularios/servicios-colectivos/${id}`;

    const filter = {
      params: {
        filter: {
          include: [
            {
              relation: "familia",
              scope: {
                fields: [
                  "id",
                  "dni",
                  "nombre",
                  "apellidos",
                  "email",
                  "telefono",
                  "direccion",
                  "localidad",
                  "provincia",
                  "tratamiento",
                ],
              },
            },
            {
              relation: "servicioProgramado",
              scope: {
                fields: ["id", "nombre"],
                include: [
                  {
                    relation: "servicio",
                    scope: {
                      fields: [
                        "id",
                        "lugarEspacioColectivo",
                        "lugarPrestacion",
                        "otrosEspacioColectivo",
                        "servicioProgramadoId",
                        "direccion",
                      ],
                    },
                  },
                ],
              },
            },
            {
              relation: "peticionFormulario",
              scope: {
                fields: ["id", "datosSolicitud"],
              },
            },
            { relation: "menor" },
          ],
          where: { servicioProgramadoId: { neq: null } },
        },
      },
    };

    if (comarcaId == null) {
      filter.params.filter.include.push({
        relation: "comarca",
        scope: { fields: ["id", "nombre"] },
      });
    }

    return ApiService.get(url, filter)
      .then(({ data }) => {
        // Ordenamos por familia prioritaria y luego por ingresos (de menos a más) Trello#24
        /*const dataSorted = data.sort((a, b) => {
          if (a.familia.familiaPrioritaria === b.familia.familiaPrioritaria)
            return (
              (parseInt(a.familia.ingresos) || 0) -
              (parseInt(b.familia.ingresos) || 0)
            );

          return b.familia.familiaPrioritaria - a.familia.familiaPrioritaria;
        });*/
        this.setFormularioData(data);
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  fetchServicioProfesionales({
    comarcaId,
    id,
  }: {
    comarcaId: string | undefined;
    id: string;
  }) {
    const url =
      comarcaId == null
        ? `/servicios/${id}/profesionales`
        : `/comarcas/${comarcaId}/servicios/${id}/profesionales`;
    return ApiService.get(url, {
      params: {},
    })
      .then(({ data }) => {
        this.setProfesional(data);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          if (err.response && err.response.status === 422) {
            this.setError(err.response.data);
          } else if (err.response && err.response.data) {
            this.setError(err.response.data);
          } else {
            const axiosError = { error: err };
            this.setError(axiosError);
          }
        } else {
          const generalError = { error: err };
          this.setError(generalError);
        }
      });
  }

  @Action
  crear({ comarcaId, servicio }: { comarcaId: string; servicio }) {
    const url =
      comarcaId == null
        ? "/servicios-programados"
        : `/comarcas/${comarcaId}/servicios-programados`;

    return ApiService.post(url, servicio).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action
  update({
    comarcaId,
    id,
    servicio,
  }: {
    comarcaId: string;
    id: string;
    servicio;
  }) {
    const url =
      comarcaId == null
        ? `/servicios-programados/${id}`
        : `/comarcas/${comarcaId}/servicios-programados/${id}`;

    return ApiService.put(url, servicio).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          this.setError(err.response.data);
        } else if (err.response && err.response.data) {
          this.setError(err.response.data);
        } else {
          const axiosError = { error: err };
          this.setError(axiosError);
        }
      } else {
        const generalError = { error: err };
        this.setError(generalError);
      }
    });
  }

  @Action({ rawError: true })
  updateSolicitud({
    id,
    comarcaId,
    solicitud,
  }: {
    id: string;
    comarcaId: string;
    solicitud;
  }) {
    const url = `/comarcas/${comarcaId}/peticion-formularios/servicio-programado/${id}`;

    return ApiService.put(url, solicitud).catch(ApiService.handleError);
  }

  @Action
  aprobarSolicitud({
    comarcaId,
    id,
    peticionFormularioId,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      id,
      peticionFormularioId,
      estado: FormularioEstado.incorporado,
    });
  }
  @Action({ rawError: true })
  rechazarSolicitud({
    comarcaId,
    id,
    peticionFormularioId,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      id,
      peticionFormularioId,
      estado: FormularioEstado.rechazado,
    });
  }
  @Action({ rawError: true })
  realizadoPorErrorSolicitud({
    comarcaId,
    id,
    peticionFormularioId,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      id,
      peticionFormularioId,
      estado: FormularioEstado.realizadorPorError,
    });
  }
  @Action({ rawError: true })
  pendiente({
    comarcaId,
    id,
    peticionFormularioId,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      id,
      peticionFormularioId,
      estado: FormularioEstado.pendiente,
    });
  }
  @Action({ rawError: true })
  listaDeEspera({
    comarcaId,
    id,
    peticionFormularioId,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
  }) {
    return this.cambiarEstadoSolicitud({
      comarcaId,
      id,
      peticionFormularioId,
      estado: FormularioEstado.listaDeEspera,
    });
  }
  @Action
  cambiarEstadoSolicitud({
    comarcaId,
    id,
    peticionFormularioId,
    estado,
  }: {
    comarcaId: string;
    id: string;
    peticionFormularioId: string;
    estado: FormularioEstado;
  }) {
    let endpoint = "";
    switch (estado) {
      case FormularioEstado.pendiente:
        endpoint = "pendiente";
        break;
      case FormularioEstado.realizadorPorError:
        endpoint = "realizadoPorError";
        break;
      case FormularioEstado.rechazado:
        endpoint = "rechazar";
        break;
      case FormularioEstado.incorporado:
        endpoint = "aprobar";
        break;
      case FormularioEstado.listaDeEspera:
        endpoint = "listaDeEspera";
    }
    if (!endpoint) throw new Error("No se ha definido el endpoint");

    const url = `/comarcas/${comarcaId}/servicios-programados/${id}/${endpoint}`;

    this.setError(null);
    return ApiService.post(url, {
      peticionFormularioId: peticionFormularioId,
    })
      .then(() => {
        const peticion = this.servicio?.peticionFormularios?.find(
          (ele) => ele.id === peticionFormularioId
        );

        if (peticion) {
          peticion.estado = estado;
        }
      })
      .catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  reasignarServicioColectivo({
    comarcaId,
    idPeticionFormulario,
    idServicioColectivo,
  }: {
    comarcaId: string;
    idPeticionFormulario: string;
    idServicioColectivo: string;
  }) {
    const url = `/comarcas/${comarcaId}/peticion-formularios/servicio-programado/${idPeticionFormulario}/reasignar`;

    return ApiService.post(url, {
      servicioProgramadoId: idServicioColectivo,
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 422) {
          throw err.response.data;
        } else if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          const axiosError = { error: err };
          throw axiosError;
        }
      } else {
        const generalError = { error: err };
        throw generalError;
      }
    });
  }

  @Action({ rawError: true })
  enviarCorreoEncuestaTodos({
    comarcaId,
    id,
  }: {
    comarcaId: string;
    id: string;
  }) {
    const url = `/comarcas/${comarcaId}/servicios-programados/${id}/enviar-encuestas`;

    return ApiService.post(url, {}).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  enviarCorreoEncuesta({
    comarcaId,
    id,
    familiaId,
  }: {
    comarcaId: string;
    id: string;
    familiaId: string;
  }) {
    const url = `/comarcas/${comarcaId}/servicios-programados/${id}/enviar-encuesta`;

    return ApiService.post(url, {
      familiaId,
    }).catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  eliminar({ comarcaId, elementId }: { comarcaId: string; elementId: string }) {
    const url = `/comarcas/${comarcaId}/servicios-programados/${elementId}`;

    return ApiService.delete(url).catch(ApiService.handleError);
  }

  @Mutation
  setError(error) {
    this.errors = error;
  }

  @Mutation
  setData(data) {
    this.servicios = data;
  }

  @Mutation
  setDataColectivos(data) {
    this.serviciosColectivos = data;
  }

  @Mutation
  setDetailData(data) {
    this.servicio = data;
  }

  @Mutation
  setFormularioData(data) {
    this.formulario = data;
  }

  @Mutation
  setProfesional(data) {
    this.profesionales = data;
  }
}
