import { tratamientoMap } from "@/core/data/tratamientos";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ServicioListado } from ".";
import { FamiliaListado } from "../models";

interface Menor {
  id: string;
  tratamiento: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
  familia?: FamiliaListado;
  servicios?: ServicioListado[];
}
interface MenorCalculated extends Menor {
  nombreComarca: string;
  nombreCompleto: string;
  responsable: string;
  familiaDni: string;
  familiaEmail: string;
  familiaTelefono: string;
  familiaDireccion: string;
  familiaLocalidad: string;
  familiaProvincia: string;
  familiaPrioritaria: string;
  familiaIngresos: string;
  tratamientoValue: string;
  fechaNacimientoString: string;
}

@Module({ namespaced: true, name: "MenorModule" })
export class MenorModule extends VuexModule {
  menores: MenorCalculated[] = [];
  menor: Menor | null = null;

  @Action({ rawError: true })
  fetchAll(comarcaId?: string) {
    const url = !comarcaId ? "/menores" : `/comarcas/${comarcaId}/menores`;

    const comarcaInclude = !comarcaId
      ? { scope: { include: [{ relation: "comarca" }] } }
      : {};
    const params = {
      filter: {
        include: [
          { relation: "familia", ...comarcaInclude },
          //{ relation: "servicios" },
        ],
      },
    };

    return ApiService.get(url, { params })
      .then(({ data }) => {
        this.setData(data);
      })
      .catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  fetchById({ comarcaId, menorId }: { comarcaId?: string; menorId: string }) {
    const base = comarcaId ? `/comarcas/${comarcaId}` : "";
    const url = `${base}/menores/${menorId}`;

    const comarcaInclude = !comarcaId
      ? { scope: { include: [{ relation: "comarca" }] } }
      : {};
    const params = {
      filter: {
        include: [
          { relation: "familia", ...comarcaInclude },
          {
            relation: "servicios",
            scope: { include: [{ relation: "servicioProgramado" }] },
          },
        ],
      },
    };

    return ApiService.get(url, { params })
      .then(({ data }) => {
        this.setDetailData(data);
      })
      .catch(ApiService.handleError);
  }

  @Action({ rawError: true })
  update({
    comarcaId,
    menorId,
    data,
  }: {
    comarcaId: string;
    menorId: string;
    data;
  }) {
    const base = comarcaId ? `/comarcas/${comarcaId}` : "";
    const url = `${base}/menores/${menorId}`;

    return ApiService.put(url, data).catch(ApiService.handleError);
  }

  @Mutation
  setData(data) {
    this.menores = data.map((ele) => {
      return {
        ...ele,
        nombreComarca: ele.familia?.comarca?.nombre ?? "",
        nombreCompleto: [ele.nombre, ele.apellidos].join(" "),
        responsable: ele.familia
          ? [ele.familia.nombre, ele.familia.apellidos].join(" ")
          : "",
        familiaDni: ele.familia?.dni,
        familiaEmail: ele.familia?.email,
        familiaTelefono: ele.familia?.telefono,
        familiaDireccion: ele.familia?.direccion,
        familiaLocalidad: ele.familia?.localidad,
        familiaProvincia: ele.familia?.provincia,
        familiaPrioritaria: ele.familia?.familiaPrioritaria,
        familiaIngresos: ele.familia?.ingresos,
        tratamientoValue: tratamientoMap[ele.tratamiento],
        fechaNacimientoString: ele.fechaNacimiento
          ? moment(ele.fechaNacimiento).format("DD/MM/YYYY")
          : "",
      };
    });
  }

  @Mutation
  setDetailData(data) {
    this.menor = data;
  }
}
